/* fira-code-300 - latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../fonts/fira-code-v21-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-code-v21-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-code-regular - latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/fira-code-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-code-v21-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-code-600 - latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../fonts/fira-code-v21-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-code-v21-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-code-500 - latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../fonts/fira-code-v21-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-code-v21-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-code-700 - latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/fira-code-v21-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-code-v21-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* alegreya-500 - latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../fonts/alegreya-v29-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-v29-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-regular - latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/alegreya-v29-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-v29-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-700 - latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/alegreya-v29-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-v29-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-italic - latin */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('../fonts/alegreya-v29-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-v29-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* alegreya-sans-regular - latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/alegreya-sans-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-sans-v21-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-sans-italic - latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('../fonts/alegreya-sans-v21-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-sans-v21-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-sans-500 - latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../fonts/alegreya-sans-v21-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-sans-v21-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-sans-700 - latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/alegreya-sans-v21-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-sans-v21-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}